<template>
  <!-- 订单入库明细管理 -->
  <div class="purchase-plan-list">
    <!-- 搜索筛选 -->
    <FormSearch
      :getdata="getdata"
      :form-inline="formInline"
      :form-item-arr="formItemArr"
    />
    <!-- 表格  分页 -->
    <div class="page-container-table">
      <Table
        :operation-button="operationButton"
        :item-data="itemData"
        :list-data="listData"
        :loading="loading"
      />
      <!-- 分页 -->
      <Pagination
        :params="formInline"
        :total="total"
        :in-article="listData.length"
        :get-data-list="getdata"
      />
    </div>
  </div>
</template>

<script>
import FormSearch from '@/components/FormSearch'
import Table from '@/components/Table'
import Pagination from '@/components/Pagination2'
import { inDetailPage } from '@/api/transactionData'
export default {
  components: {
    FormSearch,
    Table,
    Pagination
  },
  data() {
    return {
      formInline: {
        pageSize: 10,
        pageNum: 1
      },
      formItemArr: [
        { type: 'input', label: '合同编号', value: 'contractNo' },
        { type: 'input', label: '入库编号', value: 'inNo' },
        { type: 'input', label: '品名', value: 'goodsName' },
        { type: 'input', label: '材质', value: 'goodsMaterials' },
        { type: 'input', label: '规格', value: 'goodsSpecs' },
        { type: 'input', label: '产地', value: 'goodsProduct' }
      ],
      itemData: [
        { label: '合同编号', prop: 'contractNo', width: 150 },
        { label: '入库编号', prop: 'inNo', width: 150 },
        { label: '品名', prop: 'goodsName', width: 120 },
        {
          label: '材质',
          prop: 'goodsMaterials',
          width: 120
        },
        { label: '规格', prop: 'goodsSpecs', width: 120 },
        { label: '产地', prop: 'goodsProduct', width: 120 },
        { label: '件重(吨)', prop: 'goodsWeights', width: 120 },

        { label: '入库数量', prop: 'inAmount', width: 120 },
        { label: '入库数量(吨)', prop: 'inWeight', width: 120 },
        { label: '长度(米)', prop: 'length', width: 120 },
        {
          label: '入库日期',
          prop: 'inDate',
          width: 140
        },
        { label: '创建时间', prop: 'createTime', width: 140 }
      ],
      listData: [],
      operationButton: [
        { bType: 'primary', label: '详情', handleEvent: this.viewInfoTable }
      ],
      loading: false,
      total: 0,
      paymentObj: {}
    }
  },
  methods: {
    // 获取数据
    getdata(type) {
      if (!type) {
        this.formInline.pageNum = 1
      }
      inDetailPage(this.formInline, res => {
        this.listData = [...res.data.pageData]
        this.total = res.data.total
      })
    },
    // 详情
    viewInfoTable({ id }) {
      this.$router.push({
        path: '/transactionData/transactionDataPutDetails',
        query: { id }
      })
    }
  }
}
</script>
